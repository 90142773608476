// hamburger menu

const navList = document.querySelector('.mobile-menu');

if (navList) {
    const menuOpenButton = document.querySelector('#open-button');
    const bodyElement = document.querySelector("body");

    menuOpenButton.addEventListener('click', toggleHamburgerMenu);

    function toggleHamburgerMenu() {
        menuOpenButton.classList.toggle('is-active');
        bodyElement.classList.toggle("menu-is-open");
        navList.classList.toggle('mobile-menu--open');
    }

// mobile menu transitions in nested links

    const menuNextArrows = document.querySelectorAll('.arrow-next');
    const menuPrevLinks = document.querySelectorAll('.nav__item');
    const mobileNav = document.querySelector('.mobile-nav');
    const initialMobileNavHeight = mobileNav.offsetHeight;

    menuNextArrows.forEach(arrow => {
        arrow.addEventListener('click', (e) => {
            e.preventDefault();

            const submenuWrapper = arrow.closest('.nav__link').nextElementSibling;

            submenuWrapper.classList.add('submenu-wrapper--open');

            if (mobileNav.offsetHeight < submenuWrapper.offsetHeight) {
                mobileNav.style.height = submenuWrapper.offsetHeight + 'px'
            } else {
                submenuWrapper.style.height = mobileNav.offsetHeight + "px";
            }
        })
    })

    menuPrevLinks.forEach(link => {
        link.addEventListener('click', (e) => {

            var menu = document.getElementsByClassName('mobile-menu--open');
            if(menu[0] ){
                menu[0].classList.remove('mobile-menu--open');
            }
            var body = document.getElementsByTagName('body');
            body[0].classList.remove('menu-is-open');

            var openButton = document.getElementById('open-button');
            openButton.classList.remove('is-active');

        })
    })
}

// scrolled header

const header = document.querySelector(".header");

const headerScrollRange = 182;



if (header) {
    toggleClassOnScroll(header, "scrolled", headerScrollRange);
    window.addEventListener('scroll', () => toggleClassOnScroll(header, "scrolled", headerScrollRange));

}

function toggleClassOnScroll(element, className, scrollRange) {
    element.classList.toggle(className, window.pageYOffset > scrollRange);
    var focusElement = document.getElementsByClassName('desktop-nav');
    focusElement[0].focus();
}
