
export function Init(){
    window.pageUrl = document.location.origin;
        initYtPlayers();
    }

    function initYtPlayers(){
        var containers = document.querySelectorAll('.video-container');

        // Check if there is any container
        if (containers === null) return false;

        // 2. This code loads the IFrame Player API code asynchronously.
        var tag = document.createElement('script');

        tag.src = "https://www.youtube.com/iframe_api";
        var firstScriptTag = document.getElementsByTagName('script')[0];
        firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

        // 3. Create functions for player
        // Player ready function
        containers.forEach(function (container, index) {
            var ytSpinner = container.querySelector('.yt-spinner');
            var ytPlayButton = container.querySelector('.svg-sprite-play');
            var placeholderImage = container.querySelector('.video-container__image');

            if (ytPlayButton === null) return false;

            function onPlayerReady(event) {
                ytSpinner.classList.remove('show');
                event.target.playVideo();
                placeholderImage.classList.add('hidden');
            }

            // Function to play paused video and remove placeholders
            function playPausedVideo(){
                placeholderImage.classList.add('hidden');
                ytPlayButton.classList.add('hidden');
                player.playVideo();
                container.addEventListener('click', playVideo);
            }

            // Function to check status of player to run appropriate function
            function placeholderHandle(playerStatus){
                if (playerStatus == 0) { // player ended playing
                    placeholderImage.classList.remove('hidden');
                    ytPlayButton.classList.remove('hidden');
                    player.destroy();
                    container.addEventListener('click', playVideo);

                } else if (playerStatus == 1){
                    placeholderImage.classList.add('hidden');
                    ytPlayButton.classList.add('hidden');

                } else if (playerStatus == 2) { //player paused
                    container.removeEventListener('click', playVideo);
                    placeholderImage.classList.remove('hidden');
                    ytPlayButton.classList.remove('hidden');

                    container.addEventListener('click', playPausedVideo);
                }
            }

            // Player state change function
            function onPlayerStateChange(event){
                placeholderHandle(event.data);
            }

            var player

            function playVideo() {
                ytPlayButton.classList.add('hidden');
                ytSpinner.classList.add('show');

                var playerId = 'player' + index;

                player = new YT.Player(playerId, {
                    videoId: container.dataset.video,
                    origin: window.pageUrl,
                    events: {
                        'onReady': onPlayerReady,
                        'onStateChange': onPlayerStateChange
                    }
                })
            }

            container.addEventListener('click', playVideo);
        })
    }
