

export function Init(){

    var btn = document.getElementById('awards-btn');
    if(btn){
        btn.onclick = function(){
            var hiddenAwards = document.getElementById('hidden-awards');
            var btn = document.getElementById('awards-btn');
            var isOpen = btn.dataset.isOpen;
            if(isOpen=='0'){
                btn.dataset.isOpen ='1';
                hiddenAwards.classList.add('hidden-awards-active');
                btn.classList.add('section-awards__btn-active')
            }else{
                btn.dataset.isOpen ='0';
                hiddenAwards.classList.remove('hidden-awards-active');
                btn.classList.remove('section-awards__btn-active')
            }
        }
    }

}









