import * as bootstrap from 'bootstrap'
//import './front/js/01.utlis'
import './front/js/02.menu'
import { scrollToElement } from "./front/js/01.utlis";
import Glide from '@glidejs/glide'
import AOS from './front/js/05.aos.min.js' // todo-kt pociągna aos z npma
import './front/js/06.fixed-info'
import './front/js/07.accordions'

import svg4everybody from './front/js/svg4everybody.min.js'

import {InfinitePaginator} from './front/js/Paginator'
import {GoogleMaps} from './front/js/GoogleMaps'
import LootboxController from "./front/js/Cotrollers/LootboxController";
import GameController from "./front/js/Cotrollers/GameController";
import * as Contact from "./front/js/Modules/Contact";
import * as Cookie from "./front/js/Modules/Cookie";
import * as NavMenu from "./front/js/Modules/NavMenu";
import * as FaqBtn from "./front/js/Modules/FaqBtn";
import * as Draw from "./front/js/Modules/Draw";
import * as Awards from "./front/js/Modules/Awards";


document.addEventListener('DOMContentLoaded', () => {
    new Front();
});



class Front {

    constructor() {

            this.#hello();
            this.#initSvg4Everybody();
            this.#aosInit()
            this.#initScroll();
            this.#initScrollOnClick();
            this.#initNavbarScrolled();
            this.#initAddClassToNavbarOnOpen();
            //this.#initSlider();
            //this.#initHomeVideo();
            this.#initEmailLinks();
            //this.#initNewsPaginator();
            //this.#initRealisationsPaginator();
            Contact.formShowBtn();
            //YtPlayers.Init();
            NavMenu.changeFirstLink();
            FaqBtn.Init();
            Draw.Init();
            Awards.Init();
            Cookie.Init();



    }

    #hello() {
        const faqHeaders = document.querySelectorAll(".accordion__header");

        if (faqHeaders) {
            faqHeaders.forEach(header => {
                header.addEventListener('click', () => {
                    header.classList.toggle("active");
                    const body = header.nextElementSibling;

                    if (body.style.maxHeight) {
                        body.style.maxHeight = null;
                    } else {
                        body.style.maxHeight = body.scrollHeight + "px";
                    }
                })
            });
        }

        const archivesHeader = document.querySelector(".archives__header")

        if (archivesHeader) {
            archivesHeader.addEventListener('click', () => {
                archivesHeader.classList.toggle("active");
                const body = archivesHeader.nextElementSibling;

                if (body.style.maxHeight) {
                    body.style.maxHeight = null;
                } else {
                    body.style.maxHeight = body.scrollHeight + "px";
                }
            })
        }

// hamburger menu

        const navList = document.querySelector('.mobile-menu');

        if (navList) {
            const menuOpenButton = document.querySelector('#open-button');
            const bodyElement = document.querySelector("body");

            menuOpenButton.addEventListener('click', toggleHamburgerMenu);

            function toggleHamburgerMenu() {
                menuOpenButton.classList.toggle('is-active');
                bodyElement.classList.toggle("menu-is-open");
                navList.classList.toggle('mobile-menu--open');
            }

// mobile menu transitions in nested links

            const menuNextArrows = document.querySelectorAll('.arrow-next');
            const menuPrevLinks = document.querySelectorAll('.nav__item');
            const mobileNav = document.querySelector('.mobile-nav');
            const initialMobileNavHeight = mobileNav.offsetHeight;

            menuNextArrows.forEach(arrow => {
                arrow.addEventListener('click', (e) => {
                    e.preventDefault();

                    const submenuWrapper = arrow.closest('.nav__link').nextElementSibling;

                    submenuWrapper.classList.add('submenu-wrapper--open');

                    if (mobileNav.offsetHeight < submenuWrapper.offsetHeight) {
                        mobileNav.style.height = submenuWrapper.offsetHeight + 'px'
                    } else {
                        submenuWrapper.style.height = mobileNav.offsetHeight + "px";
                    }
                })
            })

            menuPrevLinks.forEach(link => {
                link.addEventListener('click', (e) => {

                    var menu = document.getElementsByClassName('mobile-menu--open');
                    if(menu[0] ){
                        menu[0].classList.remove('mobile-menu--open');
                    }
                    var body = document.getElementsByTagName('body');
                    body[0].classList.remove('menu-is-open');

                    var openButton = document.getElementById('open-button');
                    openButton.classList.remove('is-active');

                })
            })
        }

// scrolled header

        const header = document.querySelector(".header");

        const headerScrollRange = 182;



        if (header) {
            toggleClassOnScroll(header, "scrolled", headerScrollRange);
            window.addEventListener('scroll', () => toggleClassOnScroll(header, "scrolled", headerScrollRange));

        }

        function toggleClassOnScroll(element, className, scrollRange) {
            element.classList.toggle(className, window.pageYOffset > scrollRange);
            var focusElement = document.getElementsByClassName('desktop-nav');
            focusElement[0].focus();
        }

    }

    #initScroll() {
        if (window.location.hash) {
            scrollToElement(document.getElementById(window.location.hash.substring(1)), -95)
            history.pushState(null, null, window.location.hash)
        }
    }

    #initScrollOnClick() {

        document.querySelectorAll('.desktop-nav .nav__link').forEach(el => {
            el.addEventListener('click', function(e) {

                const hash = this.hash

                if (hash) {
                    var element = document.getElementById(hash.substring(1));
                    if(element){
                        e.preventDefault()
                        scrollToElement(document.getElementById(hash.substring(1)), -95)
                        history.pushState(null, null, hash)
                    }
                }
                    var menu = document.getElementsByClassName('mobile-menu--open');
                if (menu[0]){
                    menu[0].classList.remove('mobile-menu--open');
                    document.body.classList.remove('menu-is-open');
                }
            })
        })
        document.querySelectorAll('.mobile-nav .nav__link').forEach(el => {
            el.addEventListener('click', function(e) {

                const hash = this.hash

                if (hash) {
                    var element = document.getElementById(hash.substring(1));
                    if(element){
                        e.preventDefault()
                        scrollToElement(document.getElementById(hash.substring(1)), -100)
                        history.pushState(null, null, hash)
                    }
                }
                var menu = document.getElementsByClassName('mobile-menu--open');
                if (menu[0]){
                    menu[0].classList.remove('mobile-menu--open');
                    document.body.classList.remove('menu-is-open');
                }
            })
        })
    }

    #initNavbarScrolled() {
        const navbar = document.getElementById('navbar'),
            scrollClass = 'scrolled'

        if (!navbar)
            return

        const scrollHandle = () => (window.pageYOffset > 50) ? navbar.classList.add(scrollClass) : navbar.classList.remove(scrollClass);

        window.addEventListener('load', () => {
            window.addEventListener('scroll', scrollHandle)
            scrollHandle()
        })
        const navbar2 = document.getElementById('navbar'),
            scrollClass2 = 'scrolled'

        if (!navbar2)
            return

        const scrollHandle2 = () => (window.pageYOffset > 50) ? navbar2.classList.add(scrollClass2) : navbar2.classList.remove(scrollClass2);

        window.addEventListener('load', () => {
            window.addEventListener('scroll', scrollHandle2)
            scrollHandle2()
        })
    }

    #initAddClassToNavbarOnOpen() {
        const menu = document.getElementById('menu')
        const navbar = document.getElementById('navbar')
        const openClass = 'open';

        if (!navbar || !menu) return false;

        menu.addEventListener('show.bs.collapse', function () {
            navbar.classList.add(openClass);
        })

        menu.addEventListener('hide.bs.collapse', function () {
            navbar.classList.remove(openClass);
        })
    }


    /**
     * Inicjalizacja biblioteki AOS odpowiadającej za animacje elementów HTML
     */
    #aosInit()
    {
        AOS.init({
            once: true,
        })
    }

    /**
     * Inicjalizuje svg4everybody z polyfillem dla wybranych klas
     */
    #initSvg4Everybody() {
        // 0. Warunki skopiowane z biblioteki svg4everybody
        var newerIEUA = /\bTrident\/[567]\b|\bMSIE (?:9|10)\.0\b/;
        var webkitUA = /\bAppleWebKit\/(\d+)\b/;
        var olderEdgeUA = /\bEdge\/12\.(\d+)\b/;
        var edgeUA = /\bEdge\/.(\d+)\b/;
        var inIframe = window.top !== window.self;

        svg4everybody({
            polyfill: true, // 1. Domyślnie włączamy polyfill dla każdego SVGa, czyli ładujemy jego content do DOMa
            validate: function (src, svg, use) {

                // 2. Sprawdzamy czy SVG ma odpowiednią klasę i wtedy robimy polyfill. Takimi elementami można manipulować w JS i CSS
                if(svg.classList.contains('svgsprite-polyfill')) {
                    return true;
                }


                // 3. Sprawdzamy identyczny watrunek co w svg4everybody, który robi domyślny polyfill, czyli ładuje do doma np w IE
                if(newerIEUA.test(navigator.userAgent) || (navigator.userAgent.match(olderEdgeUA) || [])[1] < 10547 || (navigator.userAgent.match(webkitUA) || [])[1] < 537 || edgeUA.test(navigator.userAgent) && inIframe) {
                    return true;
                }

                // 4. Pozostałe SVGi ładujemy bez polyfilla
                return false;
            }
        });
    }

    #initSlider() {
        const mainSliderContainer = document.querySelector('.main-slider')
        const activeBullet = "glide__bullet--active";

        if(!mainSliderContainer || mainSliderContainer.querySelectorAll('.glide__slide').length <= 1) {
            return;
        }

        const bulletsContainerElement = mainSliderContainer.querySelector(".glide__bullets");

        const changeActiveBullet = (newIndex, containerElement) => {
            const glideDir = containerElement.querySelector(`[data-glide-dir="=${newIndex}"]`);

            containerElement.querySelector(`.${activeBullet}`).classList.remove(activeBullet);

            if (glideDir) glideDir.classList.add(activeBullet);
        };

        const mainSlider = new Glide(mainSliderContainer, {
            type: 'carousel',
            perTouch: true,
            animationDuration: mainSliderContainer.dataset.animation,
            autoplay: mainSliderContainer.dataset.interval,
            hoverpause: true,
            gap: 0
        });

        mainSlider.mount();

        mainSlider.on("run", () => {
            requestAnimationFrame(() => changeActiveBullet(mainSlider.index, bulletsContainerElement));
        });
    }

    /**
     * Podpina event pod przycisk play przy wideo w sekcji "O nas" na stronie głównej.
     */
    #initHomeVideo() {
        const btn = document.querySelector('.play-video-btn');
        if (!btn) {
            return;
        }

        const wrapper = btn.closest('.picture-wrapper');
        const iframe = wrapper.querySelector('.yt-video iframe');

        btn.addEventListener('click', function () {
            iframe.setAttribute('src', iframe.dataset.src);
            wrapper.classList.add('video-visible');
        });
    }

    /**
     * Wyświetla linki mailto przy mailach zabezpieczonych przez filterText.
     */
    #initEmailLinks() {
        var elements = document.querySelectorAll('.email-link');

        if (elements === null) return;

        elements.forEach(function (el) {
            var email = el.dataset.a + '@' + el.dataset.b;

            el.setAttribute('href', "mailto:" + email);
            el.querySelector('noscript').remove();
            el.insertAdjacentText('beforeend', email);
        });
    }

    #initNewsPaginator() {
        new InfinitePaginator('news-ajax-container');
    }

    #initRealisationsPaginator() {
        new InfinitePaginator('realisations-ajax-container');
    }
}