import * as $ from 'jquery';
import * as modal from 'jquery-modal'
import validate from "/assets/front/js/09jquery.validate.min";


export function initJqueryValidate() {

    addLinkToForm();
    addAnimationToButton();
    //formShowBtn();
    addAutocomplete()
    $.extend($.validator.messages, {
        required: "Pole wymagane",
        required_real: "Pole wymagane",
        remote: "Proszę o wypełnienie tego pola.",
        email: "Nieprawidłowy adres e-mail",
        url: "Proszę o podanie prawidłowego URL.",
        date: "Nieprawidłowa data",
        dateISO: "Proszę o podanie prawidłowej daty (ISO).",
        number: "Nieprawidłowa liczba",
        digits: "Dozwolone tylko cyfry",
        creditcard: "Proszę o podanie prawidłowej karty kredytowej.",
        equalTo: "Proszę o podanie tej samej wartości ponownie.",
        extension: "Nieprawidłowe rozszerzenie pliku",
        maxsize: $.validator.format("Maksymalny rozmiar pliku to {1}"),
        nipPL: "Proszę o podanie prawidłowego numeru NIP.",
        phonePL: "Proszę o podanie prawidłowego numeru telefonu",
        maxlength: $.validator.format("Maksymalna długość to {0} znaków"),
        minlength: $.validator.format("Minimalna długość to {0} znaków"),
        rangelength: $.validator.format("Proszę o podanie wartości o długości od {0} do {1} znaków."),
        range: $.validator.format("Proszę o podanie wartości z przedziału od {0} do {1}."),
        max: $.validator.format("Maksymalna wartość to {0}"),
        min: $.validator.format("Minimalna wartość to {0}"),
        pattern: $.validator.format("Pole zawiera niedozwolone znaki."),
        pesel: "Nieprawidłowy nr PESEL",
        exactlength: $.validator.format("Pole powinno zawierać {0} znaków"),
        dateRange: "Nieprawidłowa data",
        step: $.validator.format("Proszę podać wielokrotność {0}"),
        regex: "Nieprawidłowy format"
    });


    $.validator.addMethod('tel', function (value) {
        return /[0-9+ ]{15}/gm.test(value);
    }, 'Niepoprawny nr telefonu!');

    $.validator.addMethod('name1', function (value) {
        return /^[a-zA-ZęóąśłżźćńĘÓĄŚŁŻŹĆŃ\-' ]*$/im.test(value);
    }, 'Nieprawidłowe imie lub nazwisko!');

    $.validator.addMethod('name2', function (value) {
        return /^[a-zA-ZęóąśłżźćńĘÓĄŚŁŻŹĆŃ\-' ]*$/im.test(value);
    }, 'Nieprawidłowe nazwisko!');

    $.validator.addMethod("minmax", function (value, element, param) {
        let minValue = document.getElementById("minimum-value")
        let maxValue = document.getElementById("maximum-value")

        if (param === 'min')
            if (maxValue.value && parseInt(value) > parseInt(maxValue.value))
                return false

        if (param === 'max')
            if (minValue.value && parseInt(value) < parseInt(minValue.value))
                return false

        if (param === 'diff')
            if (parseInt(value) > ((parseInt(maxValue.value) + 1 - parseInt(minValue.value))) && parseInt(minValue.value) && parseInt(maxValue.value))
                return false

        return true
    }, 'Nieprawidłowa wartość');


}


var tel = document.getElementById('sendEmail_phone_number');

if (tel) {
    tel.onclick = function (e) {
        document.getElementById('label_phone').classList.remove('label-in');
        var x = e.target.value.replace(/\D/g, '').match(/(\d{0,2})(\d{0,3})(\d{0,3})(\d{0,3})/);
        while (x[2].length < 3) {
            x[2] = x[2] + '_'
        }
        while (x[3].length < 3) {
            x[3] = x[3] + '_'
        }
        while (x[4].length < 3) {
            x[4] = x[4] + '_'
        }
        e.target.value = '+48 ' + x[2] + ' ' + x[3] + ' ' + x[4]
    }
    tel.oninput = function (e) {
        document.getElementById('label_phone').classList.remove('label-in');
        var x = e.target.value.replace(/\D/g, '').match(/(\d{0,2})(\d{0,3})(\d{0,3})(\d{0,3})/);
        while (x[2].length < 3) {
            x[2] = x[2] + '_'
        }
        while (x[3].length < 3) {
            x[3] = x[3] + '_'
        }
        while (x[4].length < 3) {
            x[4] = x[4] + '_'
        }
        e.target.value = '+48 ' + x[2] + ' ' + x[3] + ' ' + x[4]
    }
}


/**
 * Walidacja formularza
 */
export function validateForm() {


    const form = document.getElementsByName('sendEmail')[0];

    const rules = {
        "sendEmail[firstname]": "name1",
        "sendEmail[phone_number]": "tel",
        sendEmail_email: {
            required: true,
            email: true
        },
        "sendEmail[textarea]": {
            required: true,
            minlength: 5,
            maxlength: 5000,
        },
        sendEmail_terms_1: {
            required: true,
        },
        sendEmail_terms_2: {
            required: true,
        },
    }

    const validateOptions = {
        focusInvalid: false,
        rules: rules,
        submitHandler: function () {
            //const file = document.getElementById("file");
            //const drawNumber = document.getElementById("drawNumber").value;
            //if (!file.files[0])
            //   return false
            document.getElementById('sendEmail_submit').classList.add('sendEmail_submit--send');
            const formData = new FormData();
            formData.append("file", '111');
            formData.append("drawNumber", 'drawNumber');
            proceedDrawing(formData, validator, form)
        }
    }


    var validator = $(form)
        .submit(function (e) {
            e.preventDefault();
        })
        .validate(validateOptions)

}


/**
 * AJAX i dalszy przebieg losowania
 */
export function proceedDrawing(data, validator, form) {

    // SERWER: /RndMachine/m4k/index.php
    // LOCAL:  /losofun-template/index.php
    // DEV:   /index.php
    var pass_data = $(form).serialize();
    $.ajax({
        url: "/contact-form",
        method: "post",
        dataType: "json",
        data: pass_data,
    })
        .done(res => {
            document.getElementById('sendEmail_submit').classList.remove('sendEmail_submit--send');
            if (res.error == 0) {
                form.reset();
                displayInfo();
            } else {
                if (typeof res.error !== 'undefined') {
                    displayError(res.error)
                }
            }
        });


}

function displayInfo() {
    var infoBlock = document.getElementById('form__info');
    infoBlock.classList.add('active');

    setTimeout(() => {
        var infoBlock = document.getElementById('form__info');
        infoBlock.classList.remove('active');
    }, "5000")
}

function displayError(errorCode) {
    var errorInfo = '';
    switch (errorCode) {
        case 1:
            errorInfo = 'Nie udało się przesłać formularza';
            break;

        case 2:
            errorInfo = 'Błąd recaptcha! Nie wysłano wiadomości!';
            break;
        case 3:
            errorInfo = 'Przesłano nieprawidłową wartość w polu Imie!';
            break;
        case 4:
            errorInfo = 'Przesłano nieprawidłową wartość w polu Nazwisko!';
            break;
        case 5:
            errorInfo = 'Przesłano nieprawidłową wartość w polu Email!';
            break;
        case 6:
            errorInfo = 'Przesłano nieprawidłową wartość w polu Treść';
            break;
        case 7:
            errorInfo = 'Podano nieprawidłowy numer telefonu';
            break;

        default:
            errorInfo = 'Wystąpił nieokreślony błąd podczas wysyłania wiadomości';
    }

    var errorBlock = document.getElementById('form__error');
    errorBlock.innerHTML = errorInfo;
    errorBlock.classList.add('active');


    setTimeout(() => {
        var errorBlock = document.getElementById('form__error');
        errorBlock.classList.remove('active');
    }, "5000")

}

export function showError(error) {
    var errorContainer = document.getElementById('error_container');
    var errorMsg = document.getElementById('error_msg');
    errorContainer.classList.add('display-block');

    if (error == 1) {
        errorMsg.textContent = 'Uwaga! Wielkość zbioru wylosowanego jest równa lub większa od zbioru wejściowego';
    } else if (error == 2) {
        errorMsg.textContent = 'Uwaga! Wielkość zbioru wykluczeń jest równa większa od zbioru wejściowego';
    } else if (error == 3) {
        errorMsg.textContent = 'Uwaga! W pliku nie ma wystarczającej liczby unikalnych wartości do przeprowadzenia losowania.';
    } else {
        errorMsg.textContent = 'Uwaga! Przekroczono maksymalny, dopuszczony rozmiar pliku';
    }
}


export function InitFormLabelAnimation() {

    var name = document.getElementById('label_name');
    var phone = document.getElementById('label_phone');
    var mail = document.getElementById('label_email');
    var msg = document.getElementById('label_textarea');

    var nameIput = document.getElementById('sendEmail_firstname');
    var phoneIput = document.getElementById('sendEmail_phone_number');
    var mailIput = document.getElementById('sendEmail_email');
    var msgIput = document.getElementById('sendEmail_textarea');

    if (name) {
        nameIput.onclick = function () {
            document.getElementById('label_name').classList.remove('label-in');
        }

        nameIput.onchange = function () {
            document.getElementById('label_name').classList.remove('label-in');
        }

        $('#sendEmail_firstname').on('keydown', function(e) {
            $('#sendEmail_firstname').trigger('click');
            if (e.key === 'Tab' || e.key === 'Enter') {
                e.preventDefault();
                $('#sendEmail_phone_number').trigger('click');
                $('#sendEmail_phone_number').click();
            }
        });

        $('#sendEmail_phone_number').on('keydown', function(evt) {
            if (evt.key === 'Tab' || evt.key === 'Enter') {
                evt.preventDefault();
                var value  = $('#sendEmail_email').val();
                $('#sendEmail_email').trigger('click');
                $('#sendEmail_email').click();
                $('#sendEmail_email').focus();
                setCaretPosition('sendEmail_email',value.length);
                var value  = $('#sendEmail_email').val();
                $('#sendEmail_email').trigger('click');
                $('#sendEmail_email').click();
                $('#sendEmail_email').focus();
                setCaretPosition('sendEmail_email',value.length);
            }
        });
        $('#sendEmail_email').on('keydown', function(evt) {
            if (evt.key === 'Tab' || evt.key === 'Enter') {
                evt.preventDefault();
                var value  = $('#sendEmail_textarea').val();
                setCaretPosition('sendEmail_textarea',value.length);
                $('#sendEmail_textarea').trigger('click');
                $('#sendEmail_textarea').click();
            }
        });



        phoneIput.onclick = function (e) {

            document.getElementById('label_phone').classList.remove('label-in');
            var x = e.target.value.replace(/\D/g, '').match(/(\d{0,2})(\d{0,3})(\d{0,3})(\d{0,3})/);
            var position = 0;
            var isFull = true;
            while (x[2].length < 3) {
                x[2] = x[2] + '_';
                position++;
                isFull = false;
            }
            if(isFull)position--;
            isFull = true;
            while (x[3].length < 3) {
                x[3] = x[3] + '_';
                position++;
                isFull = false;
            }
            if(isFull)position--;
            isFull = true;
            while (x[4].length < 3) {
                x[4] = x[4] + '_';
                position++;
                isFull = false;
            }
            e.target.value = '+48 ' + x[2] + ' ' + x[3] + ' ' + x[4]
            if(position == 1) position++;
            if(position == 5) position++;

            setCaretPosition('sendEmail_phone_number',15-position-2);
        }
        phoneIput.onchange = function (e) {
            document.getElementById('label_phone').classList.remove('label-in');
            var x = e.target.value.replace(/\D/g, '').match(/(\d{0,2})(\d{0,3})(\d{0,3})(\d{0,3})/);
            var position = 0;
            var isFull = true;
            while (x[2].length < 3) {
                x[2] = x[2] + '_';
                position++;
                isFull = false;
            }
            if(isFull)position--;
            isFull = true;
            while (x[3].length < 3) {
                x[3] = x[3] + '_';
                position++;
                isFull = false;
            }
            if(isFull)position--;
            isFull = true;
            while (x[4].length < 3) {
                x[4] = x[4] + '_';
                position++;
                isFull = false;
            }
            e.target.value = '+48 ' + x[2] + ' ' + x[3] + ' ' + x[4]
            if(position == 1) position++;
            if(position == 5) position++;

            setCaretPosition('sendEmail_phone_number',15-position-2);
        }
        phoneIput.oninput = function (e) {
            document.getElementById('label_phone').classList.remove('label-in');
            var x = e.target.value.replace(/\D/g, '').match(/(\d{0,2})(\d{0,3})(\d{0,3})(\d{0,3})/);
            var position = 0;
            var isFull = true;
            while (x[2].length < 3) {
                x[2] = x[2] + '_';
                position++;
                isFull = false;
            }
            if(isFull)position--;
            isFull = true;
            while (x[3].length < 3) {
                x[3] = x[3] + '_';
                position++;
                isFull = false;
            }
            if(isFull)position--;
            isFull = true;
            while (x[4].length < 3) {
                x[4] = x[4] + '_';
                position++;
                isFull = false;
            }
            e.target.value = '+48 ' + x[2] + ' ' + x[3] + ' ' + x[4]
            if(position == 1) position++;
            if(position == 5) position++;

            setCaretPosition('sendEmail_phone_number',15-position-2);
        }
        mailIput.onclick = function () {
            document.getElementById('label_email').classList.remove('label-in');
        }
        mailIput.onchange = function () {
            document.getElementById('label_email').classList.remove('label-in');
        }
        msgIput.onclick = function () {
            document.getElementById('label_textarea').classList.remove('label-in');
        }
        msgIput.onchange = function () {
            document.getElementById('label_textarea').classList.remove('label-in');
        }

        nameIput.onblur = function () {
            var name = document.getElementById('sendEmail_firstname');
            if (name.value == "") {
                document.getElementById('label_name').classList.add('label-in');
            }
        }
        mailIput.onblur = function () {
            var mail = document.getElementById('sendEmail_email');
            if (mail.value == "") {
                document.getElementById('label_email').classList.add('label-in');
            }
        }
        msgIput.onblur = function () {
            var msg = document.getElementById('sendEmail_textarea');
            if (msg.value == "") {
                document.getElementById('label_textarea').classList.add('label-in');
            }
        }
    }
}

export function addLinkToForm(){

    var agreChecBox = document.getElementById('sendEmail_terms_1');
    if(agreChecBox){
        var agreLabel = agreChecBox.previousSibling;
        agreLabel.innerHTML= 'Zapoznałem się z treścią <a href="/polityka-prywatnosci" target="_blank">Polityki prywatności – klauzuli informacyjnej</a> o zasadach przetwarzania danych osobowych.';

    }


}

export function formShowBtn(){

    var btn= document.getElementById('section-contact__btn');
    if(btn) {
        btn.onclick = function(){

            var btn= document.getElementById('section-contact__btn');
            var bg = document.getElementById('contact__bg');
            var form = document.getElementById('form__hidden');

            btn.classList.add('section-contact__btn--hidden');
            bg.classList.add('open');
            bg.classList.remove('close');
            form.classList.remove('section-contact--hidden');

            initJqueryValidate();
            InitFormLabelAnimation();
            validateForm();

        }
    }

}
export function setCaretPosition(elemId, caretPos) {
    var elem = document.getElementById(elemId);

    if(elem != null) {
        if(elem.createTextRange) {
            var range = elem.createTextRange();
            range.move('character', caretPos);
            range.select();
        }
        else {
            if(elem.selectionStart) {
                elem.focus();
                elem.setSelectionRange(caretPos, caretPos);
            }
            else
                elem.focus();
        }
    }
}




export function addAnimationToButton(){
   var animation= ' <div class="lds-heart"><div></div></div>';

        var btn= document.getElementById('sendEmail_submit');
        btn.innerHTML = btn.innerHTML + animation;

}


export function addAutocomplete(){
    var name = document.getElementById('sendEmail_firstname');
    var phone = document.getElementById('sendEmail_phone_number');
    var email = document.getElementById('sendEmail_email');
    name.setAttribute('autocomplete', 'name');
    phone.setAttribute('autocomplete', 'tel');
    email.setAttribute('autocomplete', 'email');
}