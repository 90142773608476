import * as $ from "jquery";
import * as YtPlayers from "./ytPlayers";

export function Init(){

    window.lastWidth = Window.innerWidth;
    window.isYtLoaded = false;
    var buttons = document.getElementsByClassName('draw__btn');
    var draws = document.getElementsByClassName('big__draw');
    if(!buttons)return false;

    for(var i=0;i<buttons.length;i++){
        buttons[i].onclick = function(){

            var id  = this.dataset.id;
            var drawContainer = document.getElementById('draw_' + id);
            var details = document.getElementById('draw-details_' + id);
            var drawsNumber = document.getElementById('drawsNumber');
            var drawsNumberValue = drawsNumber.dataset.drawsNumber;

            var winners = document.getElementById('draw-details__winners__'+id);
            if(winners){
                if(winners.dataset.isLoaded == '0'){
                    winners.dataset.isLoaded = '1';
                    var winnersId = winners.dataset.drawId;
                    var mobile = winners.dataset.isMobile;
                    var divId = 'draw-details__winners__'+id;
                    ajaxgetWiners(winnersId, mobile, divId);

                }
            }

            if (details.dataset.isOpen == 1) {
                drawContainer.classList.remove('draw-active');
                var btn = document.getElementById('btn-draw_' + id);
                details.dataset.isOpen = 0;
                details.classList.remove('draw-details-active');
                btn.classList.remove('draw__btn-active');
                if(window.isYtLoaded){
                    var player = YT.get('player' + id);
                    if (player) {
                        player.pauseVideo();
                    }
                }
            } else {

                for (var i = 0; i < drawsNumberValue; i++) {
                    var drawActive = document.getElementById('draw_' + i);
                    var draw = document.getElementById('draw-details_' + i);
                    draw.dataset.isOpen = 0;
                    draw.classList.remove('draw-details-active');
                    drawActive.classList.remove('draw-active');
                    if(window.isYtLoaded){
                        var player = YT.get('player' + i);
                        if (player) {
                            player.pauseVideo();
                        }
                    }
                }
                drawContainer.classList.add('draw-active');
                details.dataset.isOpen = 1;
                details.classList.add('draw-details-active');
                var btn = document.getElementById('btn-draw_' + id);
                btn.classList.add('draw__btn-active');
            }

            if(window.isYtLoaded == false){
                window.isYtLoaded = true;
                setTimeout(() => {
                    YtPlayers.Init();
                }, "50")
            }
        }
    }

    for(var i=0;i<draws.length;i++){
        draws[i].onclick = function(){
            var id  = this.dataset.id;
            var btn = document.getElementById('btn-draw_'+id);
            if(btn){
                btn.click();
            }
        }
    }


    addEventListener("resize", (event) => {
        if((window.lastWidth < 992 && window.innerWidth > 992) || (window.lastWidth > 992 && window.innerWidth <992)){
            var drawsNumber = document.getElementById('drawsNumber');
            var drawsNumberValue = drawsNumber.dataset.drawsNumber;
            var hiddenAwards = document.getElementById('hidden-awards');
            var btn = document.getElementById('awards-btn');
            hiddenAwards.classList.remove('hidden-awards-active');
            btn.classList.remove('section-awards__btn-active')
            for (var i = 0; i < drawsNumberValue; i++) {
                var drawActive = document.getElementById('draw_' + i);
                var draw = document.getElementById('draw-details_' + i);
                draw.dataset.isOpen = 0;
                draw.classList.remove('draw-details-active');
                drawActive.classList.remove('draw-active');
                if(window.isYtLoaded){
                    var player = YT.get('player' + i);
                    if (player) {
                        player.pauseVideo();
                    }
                }
            }
        }
        window.lastWidth = window.innerWidth;
    });

}

function onScrollDesktop( id){
    //console.log(e.currentTarget.scrollTop);


    let elem = document.getElementById('tbody-'+id).getElementsByClassName('white');
    let bodyPos =  document.getElementById('tbody-'+id).getBoundingClientRect();
    var tbody =  document.getElementById('tbody-'+id);

    var maxId = 0;
    for(var i=0;i<elem.length;i++){
        let titlesPos = elem[i].getBoundingClientRect();
        if(titlesPos.y < bodyPos.y){
            maxId = i;
            elem[i].classList.remove('on-top');
            var headId = elem[i].dataset.id;
            var subtitle = document.getElementById('winner-head-'+headId);
            subtitle.classList.remove('on-top');

        }else{
            elem[i].classList.add('on-top');
            var headId = elem[i].dataset.id;
            var subtitle = document.getElementById('winner-head-'+headId);
            subtitle.classList.add('on-top');

        }
    }
    var newTitle = elem[maxId].getElementsByClassName('table-award-title');
    var newSubTitle = document.getElementById('winner-head-'+elem[maxId].dataset.id);
    newSubTitle = newSubTitle.getElementsByClassName('winner-head');
    var titleContainer = document.getElementById('head-title-'+id);
    var subtitleContainer = document.getElementById('head-subtitle-'+id);
    titleContainer.innerHTML = newTitle[0].innerHTML;
    subtitleContainer.innerHTML = newSubTitle[0].innerHTML;
}

function onScrollMobile( id){
    let elem = document.getElementById('tbody-'+id+'-mobile').getElementsByClassName('white');
    let bodyPos =  document.getElementById('tbody-'+id+'-mobile').getBoundingClientRect();
    let tbody =  document.getElementById('tbody-'+id);

    var maxId = 0;
    for(var i=0;i<elem.length;i++){
        let titlesPos = elem[i].getBoundingClientRect();
        if(titlesPos.y < bodyPos.y){
            maxId = i;
            elem[i].classList.remove('on-top');
            var headId = elem[i].dataset.id;
            var subtitle = document.getElementById('winner-head-'+headId);
            subtitle.classList.remove('on-top');

        }else{
            elem[i].classList.add('on-top');
            var headId = elem[i].dataset.id;
            var subtitle = document.getElementById('winner-head-'+headId);
            subtitle.classList.add('on-top');

        }
    }
    var newTitle = elem[maxId].getElementsByClassName('table-award-title');
    var newSubTitle = document.getElementById('winner-head-'+elem[maxId].dataset.id);
    newSubTitle = newSubTitle.getElementsByClassName('winner-head');
    var titleContainer = document.getElementById('head-title-'+id+'-mobile');
    var subtitleContainer = document.getElementById('head-subtitle-'+id+'-mobile');
    titleContainer.innerHTML = newTitle[0].innerHTML;
    subtitleContainer.innerHTML = newSubTitle[0].innerHTML;

}

function ajaxgetWiners(id, mobile,divId){
    var passData = {
        'draw_number': id,
        'mobile': mobile,
        'div_id': divId
    }
    $.ajax({
        url: "/get-winners",
        method: "post",
        dataType: "json",
        data: passData,
    })
        .done(res => {
            var winersConteiner = document.getElementById(res.divId);
            if(res.render == true){
                winersConteiner.classList.remove('display-none');
                winersConteiner.innerHTML = res.html;

                var tbody = document.getElementById('tbody-'+res.bodyId);
                var tbodyMobile = document.getElementById('tbody-'+res.bodyId+'-mobile');
                if(tbody){
                    tbody.addEventListener('scroll', function() {
                        onScrollDesktop(res.bodyId);
                    }, false);
                }
                if(tbodyMobile){
                    tbodyMobile.addEventListener('scroll', function() {
                        onScrollMobile(res.bodyId);
                    }, false);
                }

            }else{
                winersConteiner.remove();
            }

        });
}












