const faqHeaders = document.querySelectorAll(".accordion__header");

if (faqHeaders) {
    faqHeaders.forEach(header => {
        header.addEventListener('click', () => {
            header.classList.toggle("active");
            const body = header.nextElementSibling;

            if (body.style.maxHeight) {
                body.style.maxHeight = null;
            } else {
                body.style.maxHeight = body.scrollHeight + "px";
            }
        })
    });
}

const archivesHeader = document.querySelector(".archives__header")

if (archivesHeader) {
    archivesHeader.addEventListener('click', () => {
        archivesHeader.classList.toggle("active");
        const body = archivesHeader.nextElementSibling;

        if (body.style.maxHeight) {
            body.style.maxHeight = null;
        } else {
            body.style.maxHeight = body.scrollHeight + "px";
        }
    })
}

