
var curentHeight = 0;

export function Init(){

    const btn = document.getElementById('faq_open_btn');
    if(!btn)return false;

    btn.onclick = function() {
        var faq = document.getElementsByClassName('faq');
        var accordion = faq[0].getElementsByClassName('accordion');
        var btn = document.getElementById('faq_open_btn');
        var faqHidden = document.getElementsByClassName('faq__hidden');
        if(faqHidden){
            faqHidden = faqHidden[0];
        }

        var isOpen = btn.dataset.isOpen;
        if(isOpen == '1'){
            var length = 0;
            btn.dataset.isOpen = '0';
            length = accordion.length;
            btn.classList.remove('active');
            if(faqHidden){
                var maxHeight = faqHidden.offsetHeight;
                console.log(maxHeight);
                faqHidden.style.maxHeight = maxHeight+"px";
                faqHidden.style.maxHeight = maxHeight+"px";
                faqHidden.style.transition = "max-height 0.4s ease-in-out";
                //setAttribute('style','max-height:'+maxHeight+'px;transition: max-height 0.1s ease;');

                var openElements = faqHidden.getElementsByClassName('accordion__header');
                var openSubElements = faqHidden.getElementsByClassName('accordion__body');
                for(var i =0;i< openElements.length; i++){
                    openElements[i].classList.remove('active');
                }
                for(var i =0;i< openSubElements.length; i++){
                    openSubElements[i].setAttribute('style','');
                }
                setTimeout(() => {
                    var faqHidden = document.getElementsByClassName('faq__hidden');
                    faqHidden[0].style.maxHeight = "";
                }, 20)
            }

            for(var i = 0; i<length; i++){
                accordion[i].classList.remove('show');
            }
        }else{
            btn.dataset.isOpen = '1';
            btn.classList.add('active');
            length = accordion.length;
            if(faqHidden){
                var maxHeight = 77 * (length - 5);
                faqHidden.setAttribute('style','max-height:'+maxHeight+'px;transition:max-height 0.4s ease-in-out;');
                setTimeout(() => {
                    var faqHidden = document.getElementsByClassName('faq__hidden');
                    faqHidden[0].setAttribute('style','max-height:unset;transition:max-height 0.4s ease-in-out;');
                }, 800)
            }

            for(var i = 0; i<length; i++){
                accordion[i].classList.add('show');
            }
        }
    };
}














