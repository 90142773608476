import {debounce} from "./01.utlis";

const fixedInfo = document.querySelector('.footer-fixed-info'),
    footerBottom = document.querySelector('.footer-bottom');

if (fixedInfo && footerBottom){
    setTimeout(() =>{
        fixedInfo.classList.add('show')
    }, 3000);

    const setFooterBottomPadding = () =>{
        const fixedInfoHeight = fixedInfo.clientHeight;
        footerBottom.style.paddingBottom = `${fixedInfoHeight}px`
    }

    setFooterBottomPadding();

    window.addEventListener('resize', debounce(setFooterBottomPadding, 300));
}