

export function Init(){
    let cookieInfo = getCookie("cookie_info_agree");

    if (cookieInfo != "") {
        var cookie = document.getElementById('cookie');
        if(cookie){
            cookie.remove();
        }
    } else {

        var cookie = document.getElementById('cookie');
        var cookieBtn = document.getElementById('cookie__btn');
        if(cookieBtn){
            cookie.classList.remove('cookie__hidden');
            cookieBtn.onclick = function(){
                cookie.classList.add('cookie__hidden');
                setCookie('cookie_info_agree','1',365);
                setTimeout(() => {
                    document.getElementById('cookie').remove();
                }, "1000")
            }
        }
    }
}

function setCookie(cname, cvalue, exdays) {
    const d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    let expires = "expires="+d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

function getCookie(cname) {
    let name = cname + "=";
    let ca = document.cookie.split(';');
    for(let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}










