import * as $ from "jquery";
export function changeFirstLink(){

    var mobile = document.getElementsByClassName('mobile-header');
    var desktop = document.getElementsByClassName('desktop-header');
    var links  = mobile[0].getElementsByClassName('nav__link');
    links[0].setAttribute('href', '/#aktualnosci');
    for(var i=1;i<links.length;i++){
        //links[i].setAttribute('href', links[i].getAttribute('href').substring(1));
    }
    links  = desktop[0].getElementsByClassName('nav__link');
    links[0].setAttribute('href', '/#aktualnosci');
    for(var i=1;i<links.length;i++){
        //links[i].setAttribute('href', links[i].getAttribute('href').substring(1));
    }

    $(document).scroll(function () {
        $('section').each(function () {
            if($(this).position().top <= $(document).scrollTop()+300 && ($(this).position().top + $(this).outerHeight()) > $(document).scrollTop()+300) {
                var href ='/#'+$(this).attr('id');
                var navLinks = document.getElementsByClassName('nav__link active');
               if(navLinks){
                   for(var i=0;i<navLinks.length;i++){
                       navLinks[i].classList.remove('active');
                   }
               }
                var active = document.querySelectorAll("a[href='"+href+"']");
               if(active){
                   for(var i=0;i<active.length;i++){
                       active[i].classList.add('active');
                   }
               }
            }
        });
    });

}











